import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeaturedAssetsManager from "../../components/featured-assets/FeaturedAssetsManager";
import { getAssetsThunk } from "../../redux/actions/misc-actions";
import api from "../../api";

const maxNumberPerPage = 50; // although this number is hardcoded, it should be dynamic

const FeaturedAssetsContainer = ({ open, onClose = () => {} }) => {
  const [originalAssetsMap, setOriginalAssetsMap] = useState(new Map());
  const [submitStatus, setSubmitStatus] = useState("initial");
  const assets = useSelector((state) => state.misc.assets);
  const dispatch = useDispatch();
  useEffect(() => {
    if (open)
      dispatch(
        getAssetsThunk({
          numberPerPage: maxNumberPerPage,
          page: 1,
        }),
      );
  }, [dispatch, open]);

  useEffect(() => {
    if (assets.length) {
      const originalOrderMap = new Map();
      assets.forEach((asset) => {
        originalOrderMap.set(asset.id, asset.home_order);
      });
      setOriginalAssetsMap(originalOrderMap);
    }
  }, [assets]);

  const saveHandler = async (updatedMap) => {
    if (!updatedMap.size) return;
    setSubmitStatus("loading");
    const errors = [];
    const updateRequests = Array.from(originalAssetsMap.entries()).map(([id, oldHomeOrder]) => {
      const newHomeOrder = updatedMap.get(id) || 0;
      if (oldHomeOrder === newHomeOrder) return Promise.resolve();
      const assetValues = assets.find((asset) => asset.id === id);
      if (!assetValues) return Promise.resolve();
      return api
        .updateAsset(id, { ...assetValues, home_order: newHomeOrder })
        .then((res) => {
          if (res.status !== 200) throw new Error();
        })
        .catch((err) => errors.push(id));
    });
    await Promise.all(updateRequests);
    if (errors.length) {
      setSubmitStatus("error");
    } else {
      setSubmitStatus("success");
      dispatch(getAssetsThunk({ numberPerPage: maxNumberPerPage, page: 1 }));
    }
  };

  const statusResetHandler = () => setSubmitStatus("initial");

  return (
    <FeaturedAssetsManager
      open={open}
      assets={assets}
      status={submitStatus}
      onResetStatus={statusResetHandler}
      onClose={onClose}
      onSave={saveHandler}
    />
  );
};

export default FeaturedAssetsContainer;
