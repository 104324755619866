import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import Box from "@material-ui/core/Box";
import CustomersListComponent from "../../../components/customers-owner/customer-list";
import apiAuth from "../../../api/auth";
import { isDummyUser, userCanWrite } from "../../../utils";
import StepButtons from "../../../components/step-buttons";
import InviteCustumerBtn from "../../../components/customers-owner/customer-list/invite-customer-btn";
import LayoutDashboard from "../../../components/layout-dashboard";
import BackBtn from "../../../components/customers-owner/back-btn";
import api from "../../../api";

const CustomersList = ({ custumerId }) => {
  const [customers, setCustomers] = useState([]);
  const [customerStatus, setCustomerStatus] = useState("initial");
  const [inviteUserStatus, setInviteUserStatus] = useState("initial");
  const [updateFieldUserStatus, setUpdateFieldUserStatus] = useState("initial");
  const [deleteInvitationStatus, setDeleteInvitationStatus] = useState("initial");
  const [resendInvitationStatus, setResendInvitationStatus] = useState("initial");
  const [userInviteErrors, setUserInviteErrors] = useState([]);
  const [openInvitation, setOpenInvitation] = useState(false);

  const { navigate } = useNavigate();
  const customerSearch = useSelector((state) => state.companies.search);
  const permissions = useSelector((state) => state.user.data.permissions);

  const getCustomers = async () => {
    setCustomerStatus("loading");
    const customersRes = await api.getCustomers({ companyId: custumerId });
    const invitations = await api.getInvitations({ companyId: custumerId });
    if (!customersRes.message && !invitations.message) {
      const customersList = customersRes.data?.payload.data.filter((c) => !isDummyUser(c));
      const concatList = [...invitations.data?.payload, ...customersList];
      setCustomers(concatList || []);
      setCustomerStatus("success");
      return;
    }
    setCustomerStatus("error");
  };

  const handleInviteCustomer = async ({ name, email, lastname, phone }) => {
    setInviteUserStatus("loading");
    const res = await apiAuth.inviteUser({
      name,
      email,
      lastname,
      phone,
      company_id: custumerId,
    });
    if (res.status === 200) {
      setInviteUserStatus("success");
      getCustomers();
    } else {
      setInviteUserStatus("error");
    }
    setUserInviteErrors(res.response?.data?.message);
  };

  const handleUpdateFieldUser = async ({ name, lastname, phone, id }) => {
    setUpdateFieldUserStatus("loading");
    const resUpdate = await api.updateUser(id, { name, lastname, phone });
    if (resUpdate.status === 200) {
      setUpdateFieldUserStatus("success");
      getCustomers();
    } else {
      setUpdateFieldUserStatus("error");
    }

    setTimeout(() => {
      setUpdateFieldUserStatus("initial");
    }, 1500);
  };

  const handleDeleteInvitation = async (invitationId) => {
    setDeleteInvitationStatus("loading");
    const resUpdate = await api.deleteUserInvitation(invitationId);
    if (resUpdate.status === 200) {
      setDeleteInvitationStatus("success");
      const intationRemove = customers.filter(({ id }) => id !== invitationId);
      setCustomers(intationRemove);
    } else {
      setDeleteInvitationStatus("error");
    }
  };

  const handleResendInvitation = async (invitationId) => {
    setResendInvitationStatus("loading");
    try {
      const resUpdate = await api.resendUserInvitation(invitationId);
      if (resUpdate.status === 200) {
        setResendInvitationStatus("success");
      } else {
        setResendInvitationStatus("error");
      }
    } catch (error) {
      setResendInvitationStatus("error");
    }
  };

  const handleBack = () => {
    navigate(customerSearch ? `/administrator/clients?search=${customerSearch}` : "/administrator/clients");
  };

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <>
      <LayoutDashboard>
        <CustomersListComponent
          customers={customers}
          customerStatus={customerStatus}
          inviteUserStatus={inviteUserStatus}
          userInviteErrors={userInviteErrors}
          inviteCustomer={handleInviteCustomer}
          onUpdateFieldUser={handleUpdateFieldUser}
          updateFieldUserStatus={updateFieldUserStatus}
          onDeleteInvitation={handleDeleteInvitation}
          deleteInvitationStatus={deleteInvitationStatus}
          onResendInvitation={handleResendInvitation}
          resendInvitationStatus={resendInvitationStatus}
          openInvitation={openInvitation}
          setOpenInvitation={setOpenInvitation}
          canEdit={userCanWrite(permissions)}
        />
      </LayoutDashboard>
      <StepButtons
        stepButtonComponent={
          <Box display="flex" flexDirection="row">
            <BackBtn onBack={handleBack} />
            {userCanWrite(permissions) ? <InviteCustumerBtn onOpenInvitation={() => setOpenInvitation(true)} /> : <></>}
          </Box>
        }
      />
    </>
  );
};

export default CustomersList;
