import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import theme from "../../theme";
import CheckIcon from "../../icons/success-circle-icon";
import LoadingBar from "../loading-bar";

const BookingErrorMessage = ({ message = "" }) => {
  switch (message) {
    case "unauthorized_user":
      return (
        <Typography variant="body1">
          <Trans>Usuario no autorizado.</Trans>
        </Typography>
      );

    case "space_activity_coefficient_exception":
      return (
        <Typography variant="body1">
          <Trans>La actividad comercial seleccionada no es válida.</Trans>
        </Typography>
      );

    case "space_not_available":
      return (
        <Typography variant="body1">
          <Trans>El espacio o espacio publicitario no está disponible. Revisa las fechas seleccionadas.</Trans>
        </Typography>
      );

    case "invalid_asset_for_booking":
      return (
        <Typography variant="body1">
          <Trans>El espacio o espacio publicitario pertenece a otro centro comercial.</Trans>
        </Typography>
      );

    case "empty_booking":
      return (
        <Typography variant="body1">
          <Trans>
            La reserva no contiene ningún espacio o espacio publicitario. Debes agregar al menos uno para continuar.
          </Trans>
        </Typography>
      );

    case "unselected_booking_category":
      return (
        <Typography variant="body1">
          <Trans>Debes seleccionar una categoría para la reserva antes de continuar.</Trans>
        </Typography>
      );

    case "unselected_booking_payment_plan":
      return (
        <Typography variant="body1">
          <Trans>Selecciona un método de pago para poder crear la reserva.</Trans>
        </Typography>
      );

    default:
      return (
        <Box display="flex" flexDirection="column" alignItems="left">
          <Typography variant="body1">
            <Trans>
              Ocurrió un error al crear la reserva. Inténtalo nuevamente o contacta a Bookacorner con el ID de la
              reserva.
            </Trans>
          </Typography>
        </Box>
      );
  }
};

const StepCreateComponent = ({ onNext, resultCreate }) => (
  <Box display="flex" justifyContent="center" pb={3}>
    {resultCreate.status === "loading" && (
      <Box mb={3} width="100%">
        <LoadingBar height="10px" mt={2}>
          <Trans>Creando reserva....</Trans>
        </LoadingBar>
      </Box>
    )}
    {resultCreate.status === "success" && (
      <Box display="flex" flexDirection="column" alignItems="center" width={400}>
        <CheckIcon fill={theme.palette.successGreen} width={56} height={56} />
        <Box mb={3}>
          <Typography variant="subtitle1" style={{ color: theme.palette.successGreen }}>
            <Trans>La reserva</Trans>
            <span style={{ marginLeft: 3, marginRight: 3 }}>#{resultCreate.bookingId}</span>
            <Trans>se ha generado con éxito.</Trans>
          </Typography>
        </Box>
        <Button variant="contained" color="primary" style={{ width: 150 }} onClick={onNext}>
          <Trans>Aceptar</Trans>
        </Button>
      </Box>
    )}
    {resultCreate.status === "error" && (
      <Box display="flex" flexDirection="column">
        <Box mb={3} width="100%" style={{ wordBreak: "break-word" }}>
          <Typography variant="subtitle1" style={{ color: theme.palette.error.main }}>
            <Trans>Oops! Lo sentimos, hubo un inconveniente:</Trans>{" "}
            {resultCreate.error === "FAILED_CREATE_BOOKING" && <BookingErrorMessage message={resultCreate.message} />}
            {(resultCreate.error === "FAILED_TEMPLATE_CONTRACT" || resultCreate.error === "FAILED_UPDATE_CONTRACT") && (
              <Trans>La reserva se creó correctamente, pero hubo un error al generar el contrato.</Trans>
            )}
            {resultCreate.error === "FAILED_GENERATE_PREVIEW_CONTRACT" && (
              <Trans>
                La reserva y el contrato se crearon correctamente, pero no se pudo generar la vista previa del contrato.
              </Trans>
            )}
          </Typography>
        </Box>
        <Button variant="text" color="primary" onClick={onNext}>
          <Trans>Salir</Trans>
        </Button>
      </Box>
    )}
  </Box>
);

export default StepCreateComponent;
