import React, { useState, useCallback, useEffect } from "react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Button, Typography, CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { formatDateUtc } from "../../utils/dateUtils";
import TrashIcon from "../../icons/trash-icon";
import OwnerModal from "../owner-modal";
import theme from "../../theme";
import Calendar from "../calendar";
import ActionResultModal from "../action-result-modal";
import { SPACE_TYPE } from "../../utils";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
    gridGap: 20,
  },
  button: {
    width: 190,
    height: 42,
  },
}));

const BlockDatesModal = ({
  isOpen,
  spaceType,
  blockedDates = [],
  selectedBlockDates,
  onCreateBlockDates,
  onUpdateBlockDates,
  createBlockDatesStatus,
  updateBlockDatesStatus,
  onDeleteBlockDates,
  deleteBlockDatesStatus,
  onClose,
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const [selectedDates, setSelectedBlockedDates] = useState(selectedBlockDates || {});
  const [description, setDescription] = useState(selectedBlockDates.description);
  const [isActionResultOpen, setIsActionResultOpen] = useState(false);
  const [actionResultStatus, setActionResultStatus] = useState("initial");
  const [resultMessage, setResultMessage] = useState({ successMessage: "", errorMessage: "" });
  const [action, setAction] = useState("");

  useEffect(() => {
    if (isOpen && selectedBlockDates) {
      setSelectedBlockedDates(selectedBlockDates);
      setDescription(selectedBlockDates.description);
    }

    if (selectedBlockDates.blocked_date_ranges_id) {
      setAction("update");
    } else {
      setAction("create");
    }
  }, [isOpen, selectedBlockDates]);

  const getModalTexts = useCallback(() => {
    switch (action) {
      case "create":
        setResultMessage({
          successMessage: <Trans>El bloqueo se ha creado con éxito.</Trans>,
          errorMessage: <Trans>Falló la creación del bloqueo. Por favor, inténtalo de nuevo.</Trans>,
        });
        break;

      case "update":
        setResultMessage({
          successMessage: <Trans>El bloqueo se ha actualizado con éxito.</Trans>,
          errorMessage: <Trans>Ocurrió un error al actualizar el bloqueo. Por favor, inténtalo de nuevo.</Trans>,
        });
        break;

      case "delete":
        setResultMessage({
          successMessage: <Trans>El bloqueo se ha eliminado con éxito.</Trans>,
          errorMessage: <Trans>Ocurrió un error al eliminar el bloqueo. Por favor, inténtalo de nuevo.</Trans>,
        });
        break;
      default:
      // Handle default case if needed
    }
  }, [action, setResultMessage]);

  useEffect(() => {
    getModalTexts();
  }, [action, getModalTexts]);

  useEffect(() => {
    if (
      createBlockDatesStatus === "success" ||
      updateBlockDatesStatus === "success" ||
      deleteBlockDatesStatus === "success"
    ) {
      setIsActionResultOpen(true);
      setActionResultStatus("success");
    } else if (
      createBlockDatesStatus === "error" ||
      updateBlockDatesStatus === "error" ||
      deleteBlockDatesStatus === "error"
    ) {
      setIsActionResultOpen(true);
      setActionResultStatus("error");
    }
  }, [createBlockDatesStatus, updateBlockDatesStatus, deleteBlockDatesStatus]);

  const handleSelectDates = (dates) => {
    setSelectedBlockedDates((prev) => ({ ...prev, start: dates[0], end: dates[1] }));
  };

  const handleResetDates = () => {
    setSelectedBlockedDates({ start: "", end: "" });
  };

  const handleIsTileDisabled = ({ date }) => {
    const formattedDate = formatDateUtc(date, "YYYY-MM-DD");
    const filteredBlockDates = blockedDates.filter((block) => block.id !== selectedBlockDates.blocked_date_ranges_id);

    return filteredBlockDates.some((block) => formattedDate >= block.start && formattedDate <= block.end);
  };

  const closeModal = () => {
    setIsActionResultOpen(false);
    setActionResultStatus("initial");
    onClose();
  };

  return (
    <>
      <ActionResultModal
        isOpen={isActionResultOpen}
        status={actionResultStatus}
        successMessage={resultMessage.successMessage}
        errorMessage={resultMessage.errorMessage}
        onClose={closeModal}
      />

      <OwnerModal
        title={action === "create" ? i18n._(t`Crear bloqueo`) : i18n._(t`Actualizar bloqueo`)}
        open={isOpen}
        onClose={onClose}
        width={650}
      >
        <Box className={classes.container} mt={2}>
          <Box px={2}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12}>
                <Box display="flex" flexDirection="column" mb={2}>
                  {action === "create" ? (
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                      <Trans>Selecciona las fechas para el nuevo bloqueo</Trans>
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                      <Trans>Actualiza o elimina el bloqueo de las fechas seleccionadas</Trans>
                    </Typography>
                  )}
                </Box>

                <Calendar
                  startDate={new Date(selectedDates.start) || null}
                  endDate={new Date(selectedDates.end) || null}
                  minDate={new Date()}
                  tileDisabled={handleIsTileDisabled}
                  onChange={handleSelectDates}
                  isDouble
                />

                <Box
                  onClick={handleResetDates}
                  style={{ cursor: "pointer", width: "100%", justifyContent: "flex-end" }}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mt={1}
                  mb={1}
                >
                  <Typography variant="body1" style={{ textDecoration: "underline" }}>
                    <Trans>Limpiar fechas</Trans>
                  </Typography>
                  <TrashIcon style={{ width: "11px", marginLeft: theme.spacing(1 / 2) }} />
                </Box>

                <Box display="column" gridGap={10}>
                  <Typography variant="subtitle1">
                    <Trans>Razón de bloqueo</Trans>
                  </Typography>

                  <TextField
                    rows={3}
                    multiline
                    variant="outlined"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="space-between" gridGap={20}>
            {action === "create" ? (
              <Button
                onClick={() => {
                  setAction("create");
                  onCreateBlockDates(
                    spaceType === SPACE_TYPE.SPACE ? selectedBlockDates.space_id : selectedBlockDates.adspace_id,
                    selectedDates.start,
                    selectedDates.end,
                    description,
                  );
                }}
                disabled={!(selectedDates.start && selectedDates.end) || createBlockDatesStatus === "loading"}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {createBlockDatesStatus === "loading" ? (
                  <Box display="flex" alignItems="center" gridGap={10}>
                    <CircularProgress color="inherit" size={20} />
                    <Trans>Crear bloqueo</Trans>
                  </Box>
                ) : (
                  <Trans>Crear bloqueo</Trans>
                )}
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setAction("update");
                    onUpdateBlockDates(
                      spaceType === SPACE_TYPE.SPACE ? selectedBlockDates.space_id : selectedBlockDates.adspace_id,
                      selectedBlockDates.blocked_date_ranges_id,
                      selectedDates.start,
                      selectedDates.end,
                      description,
                    );
                  }}
                  disabled={!(selectedDates.start && selectedDates.end) || deleteBlockDatesStatus === "loading"}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {updateBlockDatesStatus === "loading" ? (
                    <Box display="flex" alignItems="center" gridGap={10}>
                      <CircularProgress color="inherit" size={20} />
                      <Trans>Actualizar bloqueo</Trans>
                    </Box>
                  ) : (
                    <Trans>Actualizar bloqueo</Trans>
                  )}
                </Button>
                <Button
                  onClick={() => {
                    setAction("delete");
                    onDeleteBlockDates(
                      spaceType === SPACE_TYPE.SPACE ? selectedBlockDates.space_id : selectedBlockDates.adspace_id,
                      selectedBlockDates.blocked_date_ranges_id,
                    );
                  }}
                  disabled={updateBlockDatesStatus === "loading"}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                >
                  {deleteBlockDatesStatus === "loading" ? (
                    <Box display="flex" alignItems="center" gridGap={10}>
                      <CircularProgress color="inherit" size={20} />
                      <Trans>Eliminar bloqueo</Trans>
                    </Box>
                  ) : (
                    <Trans>Eliminar bloqueo</Trans>
                  )}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </OwnerModal>
    </>
  );
};

export default BlockDatesModal;
