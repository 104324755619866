import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "@lingui/macro";
import { useNavigate } from "../../hooks/navigation";
import SpacesOwnerComponent from "../../components/spaces-owner";
import { getSpacesOwnerThunk, getSpaceOwnerSuccess } from "../../redux/actions/spaces-owner-actions";
import { resetSpace } from "../../redux/actions/space-actions";
import {
  getRowsFromWindowSize,
  getSpaceSlug,
  downloadBlobFiles,
  userCanWrite,
  filterColumnsDisplayTableToString,
} from "../../utils";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import api from "../../api";

// config
import config from "../../config";
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";
import CreateSpaceWizardContainer from "../create-space-wizard/index";

const SpacesOwnerContainer = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();

  const spaces = useSelector((state) => state.spacesOwner.spaces);
  const pagination = useSelector((state) => state.spacesOwner.pagination);
  const status = useSelector((state) => state.spacesOwner.status);
  const filters = useSelector((state) => state.spacesOwner.filters);
  const searchWords = useSelector((state) => state.spacesOwner.searchWords);
  const user = useSelector((state) => state.user.data);
  const userId = useSelector((state) => state.user.data.id);
  const permissions = useSelector((state) => state.user.data.permissions);

  const [sort, setSort] = useState("");
  const [columns, setColumns] = useState("");
  const [visibilityStatus, setVisibilityStatus] = useState({});
  const [openCreateSpace, setOpenCreateSpace] = useState(false);

  useEffect(() => {
    dispatch(setDashboardTitle(t`Espacios`));
    dispatch(resetSpace());
    dispatch(resetAllAdSpaces());
  }, []);

  useEffect(() => {
    // first time is entering here without filters on
    if (Array.isArray(filters)) {
      return;
    }
    dispatch(
      getSpacesOwnerThunk({
        numberPerPage: getRowsFromWindowSize(),
        page: pagination?.page,
        sort,
        filters,
        search: searchWords,
      }),
    );
  }, [sort, filters]);

  useEffect(() => {
    if (!searchWords) {
      // if we dont have any filters working, it means it shouldn't ask anything
      return;
    }
    dispatch(
      getSpacesOwnerThunk({
        numberPerPage: getRowsFromWindowSize(),
        page: 1,
        sort,
        filters,
        search: searchWords,
      }),
    );
  }, [searchWords]);

  const handleChangePage = (evt, page) => {
    dispatch(
      getSpacesOwnerThunk({
        numberPerPage: getRowsFromWindowSize(),
        page,
        sort,
        filters,
        search: searchWords,
      }),
    );
  };

  const handleTableChange = (action, tableState) => {
    const columnsDisplay = filterColumnsDisplayTableToString(tableState);
    setColumns(columnsDisplay);
    if (action === "sort") setSort(tableState.sortOrder);
  };

  const handleOpenCreateSpace = () => setOpenCreateSpace(true);
  const handleCloseCreateSpace = () => {
    setOpenCreateSpace(false);
    dispatch(resetSpace());
  };

  const handleEditSpace = (rowData) => {
    navigate(`/administrator/spaces/${rowData.id}/description`);
  };

  const handleViewSpace = (rowData) => {
    window.open(`${config.HOME_URL}/spaces/${getSpaceSlug(rowData)}`, "_blank");
  };

  const handleClickPublish = async (spaceId, searchable) => {
    if (visibilityStatus[spaceId] !== "loading") {
      setVisibilityStatus((vs) => ({ ...vs, [spaceId]: "loading" }));
      const res = searchable ? api.hideListing(spaceId, userId) : api.publishListing(spaceId, userId);
      res.then((data) => {
        if (data.status === 200) {
          setVisibilityStatus((vs) => ({ ...vs, [spaceId]: "success" }));
          dispatch(getSpaceOwnerSuccess(data.data?.payload));
        } else {
          setVisibilityStatus((vs) => ({ ...vs, [spaceId]: "error" }));
        }
      });
    }
  };

  const downloadSpaceData = async (format) => {
    const res = await api.downloadSpaceData({
      exportFormat: format,
      filters,
      sort,
      columns,
    });
    downloadBlobFiles(res, `Espacios.${format}`);
  };

  return (
    <>
      <SpacesOwnerComponent
        onTableChange={handleTableChange}
        onRowClick={handleEditSpace}
        onClickAdd={handleOpenCreateSpace}
        onEyeClick={handleViewSpace}
        publishSpace={handleClickPublish}
        downloadTableData={downloadSpaceData}
        visibilityStatus={visibilityStatus}
        spaces={spaces}
        status={status}
        currentUserId={user.id}
        pagination={pagination}
        onChangePage={handleChangePage}
        canEdit={userCanWrite(permissions)}
      />
      <CreateSpaceWizardContainer open={openCreateSpace} onClose={handleCloseCreateSpace} />
    </>
  );
};

export default SpacesOwnerContainer;
