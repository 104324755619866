import { makeStyles } from "@material-ui/core/styles";
import React, { useMemo, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Typography } from "@material-ui/core";
import { useNavigate } from "../../hooks/navigation";
import Table from "../table";
import TableFooter from "../table-footer";
import AssetToolbar from "./asset-toolbar";
import TableDataDownload from "../table-data-download";
import FeaturedAssetsContainer from "../../containers/featured-assets";

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: "absolute",
    right: 50,
    bottom: 50,
  },
  notification: {
    color: theme.palette.primary.main,
  },
  filters: {
    height: 25,
    textTransform: "none",
    display: "flex",
    justifyContent: "flex-start",
    marginRight: theme.spacing(1),
    borderRadius: 4,
  },
  tooltip: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const customBodyRender = (assets, dataIndex, classes) => (
  <Box>
    <Tooltip arrow title={assets[dataIndex].address}>
      <span className={classes.tooltip}>
        {assets[dataIndex].address.length > 40
          ? `${assets[dataIndex].address.substring(0, 39)}...`
          : assets[dataIndex].address}
      </span>
    </Tooltip>
  </Box>
);

function OwnerAssets({
  assets = [],
  status,
  pagination,
  onChangePage,
  onTableChange = () => {},
  onDownloadTableData,
  userRole,
}) {
  const [openFeaturedAssets, setOpenFeaturedAssets] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const { navigate } = useNavigate();
  const { i18n } = useLingui();
  const classes = useStyles();

  const handleTableData = () => {
    const data = assets.map((l) => {
      return {
        id: l.id,
        name: l.name,
        city: l.city?.name,
        address: l.address,
        country: l.city?.country?.name,
        taxId: l.tax_id,
      };
    });

    return data;
  };

  const columns = [
    { label: "ID", name: "id", options: { sort: true } },
    { label: `${i18n._(t`Centro comercial`)}`, name: "name", options: { sort: true } },
    {
      label: `${i18n._(t`Ciudad`)}`,
      name: "city",
      options: { sort: true },
    },
    {
      label: `${i18n._(t`País`)}`,
      name: "country",
      options: { sort: true },
    },
    {
      label: `${i18n._(t`Dirección`)}`,
      name: "address",
      options: {
        customBodyRenderLite: (dataIndex) => customBodyRender(assets, dataIndex, classes),
        sort: true,
      },
    },
    {
      label: `${i18n._(t`Tax ID`)}`,
      name: "taxId",
      options: { sort: true },
    },
  ];

  const handleGoToLandlords = () => {
    navigate("/administrator/assets-landlords/landlords");
  };

  const handleGoToUsersReport = () => {
    navigate("/administrator/assets-landlords/users-report");
  };

  const handleGoToAssetForm = (dataIndex) => {
    navigate(`/administrator/assets-landlords/assets/${dataIndex[0]}`);
  };

  const handleOpenDownloadModal = () => {
    setOpenDownloadModal(true);
    return false;
  };

  const handleOpenFeaturedAssets = () => setOpenFeaturedAssets(true);
  const handleCloseFeaturedAssets = () => setOpenFeaturedAssets(false);

  const customToolbar = useMemo(() => <AssetToolbar onTableChange={onTableChange} />, [onTableChange]);
  const customFooter = useMemo(
    () => (
      <TableFooter
        primaryButton={i18n._(t`Ordenar Centros Destacados`)}
        onPrimaryButton={() => handleOpenFeaturedAssets()}
        showPrimaryButtonIcon={false}
        pagination={pagination}
        onChangePage={onChangePage}
        canCreate
      />
    ),
    [pagination, onChangePage, i18n],
  );

  return (
    <>
      <Box pl={2} pr={2}>
        <TableDataDownload
          open={openDownloadModal}
          onClose={() => setOpenDownloadModal(false)}
          onDownload={onDownloadTableData}
        />
        <Tabs value="Assets" indicatorColor="primary" textColor="primary" variant="fullWidth" centered>
          <Tab
            value="Assets"
            label={
              <Typography variant="subtitle1">
                <Trans>Centros Comerciales</Trans>
              </Typography>
            }
          />
          <Tab
            value="Landlords"
            label={
              <Typography variant="subtitle1">
                <Trans>Propietarios</Trans>
              </Typography>
            }
            onClick={handleGoToLandlords}
          />
          {userRole === "Area Manager" && (
            <Tab
              value="UsersReport"
              label={
                <Typography variant="subtitle1">
                  <Trans>Reporte de usuarios</Trans>
                </Typography>
              }
              onClick={handleGoToUsersReport}
            />
          )}
        </Tabs>
        {/** this is a fix becuse of the extra height provided by the tabs. * */}
        {/** When refactoring this view, remove this height fix * */}
        <Box height={window.innerHeight - 190}>
          <Table
            data={handleTableData()}
            columns={columns}
            isLoading={status === "loading"}
            onDownload={handleOpenDownloadModal}
            options={{
              onRowClick: handleGoToAssetForm,
              onTableChange,
              customToolbar: () => customToolbar,
              customFooter: () => customFooter,
            }}
          />
        </Box>
      </Box>
      <FeaturedAssetsContainer open={openFeaturedAssets} onClose={handleCloseFeaturedAssets} />
    </>
  );
}

export default OwnerAssets;
