import React from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TableFooter from "@material-ui/core/TableFooter";
import { AddSharp } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import theme from "../../theme-admin";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  footerStyle: {
    display: "flex",
    justifyContent: "space-around",
    padding: "0px 0px 5px 0px",
    alignItems: "flex-end",
    "&:hover": {
      color: "black",
    },
  },
  actions: {
    flex: 4,
    display: "flex",
    justifyContent: "flex-start",
  },
  pageNumber: {
    padding: 2,
  },
  chevron: {
    height: "20px",
    width: "20px",
    fill: "black",
  },
}));

const CustomTableFooter = ({
  pagination = {},
  onChangePage,
  canCreate = true,
  primaryButton,
  secondaryButton,
  onPrimaryButton,
  showPrimaryButtonIcon = true,
  onSecondaryButton,
  showSecondaryButtonIcon = true,
  actionButtons = () => {},
  showActionButtons = true,
}) => {
  const classes = useStyles(theme);

  return (
    <TableFooter className={classes.container}>
      <Box>
        <Box className={classes.footerStyle} colSpan={1000}>
          <Box className={classes.actions}>
            {showActionButtons ? actionButtons() : null}
            {canCreate && (
              <>
                {primaryButton && (
                  <Button size="small" variant="text" color="primary" onClick={onPrimaryButton}>
                    {primaryButton}
                    {showPrimaryButtonIcon && <AddSharp />}
                  </Button>
                )}
                {secondaryButton && (
                  <Button size="small" variant="text" color="primary" onClick={onSecondaryButton}>
                    {secondaryButton}
                    {showSecondaryButtonIcon && <AddSharp />}
                  </Button>
                )}
              </>
            )}
          </Box>
          <Box className={classes.paginate}>
            <Pagination count={pagination.totalPages} color="primary" page={pagination.page} onChange={onChangePage} />
          </Box>
        </Box>
      </Box>
    </TableFooter>
  );
};

export default CustomTableFooter;
