import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { t } from "@lingui/macro";
import { useNavigate } from "../../hooks/navigation";
import AdSpacesOwnerComponent from "../../components/ad-spaces-owner";
import { getAdminAdSpacesThunk, updateAdSpaceList, resetAdSpace } from "../../redux/actions/adspaces-actions";
import { getRowsFromWindowSize, downloadBlobFiles, userCanWrite, filterColumnsDisplayTableToString } from "../../utils";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import api from "../../api";
import config from "../../config";
import { resetAllSpaces } from "../../redux/actions/spaces-owner-actions";
import CreateSpaceWizardContainer from "../create-space-wizard/index";

const AdSpacesOwnerContainer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { navigate } = useNavigate();

  const adSpaces = useSelector((state) => state.adspaces.adSpaces);
  const pagination = useSelector((state) => state.adspaces.pagination);
  const filters = useSelector((state) => state.adspaces.filters);
  const searchWords = useSelector((state) => state.adspaces.searchWords);
  const permissions = useSelector((state) => state.user.data.permissions);

  const status = useSelector((state) => state.adspaces.adSpacesStatus);
  const user = useSelector((state) => state.user.data);

  const [sort, setSort] = useState();
  const [visibilityStatus, setVisibilityStatus] = useState({});
  const [columns, setColumns] = useState("");
  const [openCreateSpace, setOpenCreateSpace] = useState(false);

  useEffect(() => {
    dispatch(setDashboardTitle(t`Espacios Publicitarios`));
    dispatch(resetAllSpaces());
  }, []);

  useEffect(() => {
    if (Array.isArray(filters) || !searchWords) return;
    dispatch(resetAdSpace());
    const adSpacesParams = { numberPerPage: getRowsFromWindowSize(), page: 1, sort, filters };
    if (searchWords) adSpacesParams.search = searchWords;
    dispatch(getAdminAdSpacesThunk(adSpacesParams));
  }, [dispatch, sort, filters, searchWords]);

  const handleChangePage = (evt, page) => {
    dispatch(
      getAdminAdSpacesThunk({
        numberPerPage: getRowsFromWindowSize(),
        page,
        sort,
        filters,
      }),
    );
  };

  const handleTableChange = (action, tableState) => {
    const columnsDisplay = filterColumnsDisplayTableToString(tableState);
    setColumns(columnsDisplay);
    if (action === "sort") setSort(tableState.sortOrder);
  };

  const handleEditSpace = (rowData) => {
    navigate(`/administrator/adspaces/${rowData.id}/title`);
  };
  const handleViewSpace = (rowData) => {
    window.open(`${config.HOME_URL}/active-adspaces/${rowData.asset?.id}`, "_blank");
  };

  const handleOpenCreateAdSpace = () => setOpenCreateSpace(true);
  const handleCloseCreateAdSpace = () => {
    setOpenCreateSpace(false);
    dispatch(resetAdSpace());
  };

  const handleClickPublish = async (adSpaceId, searchable) => {
    if (visibilityStatus[adSpaceId] !== "loading") {
      if (params.adSpaceId !== "new") {
        setVisibilityStatus((vs) => ({ ...vs, [adSpaceId]: "loading" }));
        const res = searchable ? api.hideAdSpace(adSpaceId) : api.publishAdSpace(adSpaceId);
        res.then((data) => {
          if (data.status === 200) {
            setVisibilityStatus((vs) => ({ ...vs, [adSpaceId]: "success" }));
            const adSpace = adSpaces.find((ad) => ad.id === adSpaceId);
            adSpace.searchable = !searchable;
            dispatch(updateAdSpaceList(adSpace));
          } else {
            setVisibilityStatus((vs) => ({ ...vs, [adSpaceId]: "error" }));
          }
        });
      } else {
        const adSpace = adSpaces.find((ad) => ad.id === adSpaceId);
        adSpace.searchable = !searchable;
        setVisibilityStatus((vs) => ({ ...vs, [adSpaceId]: "success" }));
        updateAdSpaceList(adSpace);
      }
    }
  };

  const downloadAdSpaceData = async (format) => {
    const res = await api.downloadAdSpaceData({
      exportFormat: format,
      filters,
      sort,
      columns,
    });
    downloadBlobFiles(res, `EspaciosPublicitarios.${format}`);
  };

  return (
    <>
      <AdSpacesOwnerComponent
        onTableChange={handleTableChange}
        onRowClick={handleEditSpace}
        onClickAdd={handleOpenCreateAdSpace}
        onEyeClick={handleViewSpace}
        status={status}
        adSpaces={adSpaces}
        downloadTableData={downloadAdSpaceData}
        visibilityStatus={visibilityStatus}
        publishAdSpace={handleClickPublish}
        currentUserId={user.id}
        pagination={pagination}
        onChangePage={handleChangePage}
        canEdit={userCanWrite(permissions)}
      />
      <CreateSpaceWizardContainer open={openCreateSpace} onClose={handleCloseCreateAdSpace} isAdSpace />
    </>
  );
};

export default AdSpacesOwnerContainer;
