import React, { useRef, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import MenuItem from "@material-ui/core/MenuItem";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "../../select/select-validator";
import AdSpaceStepButtons from "../ad-space-step-buttons";
import { useNavigate } from "/src/hooks/navigation";

import theme from "../../../theme";
import Map from "../../google-maps";
import config from "../../../config";
import ConfirmationModal from "./confirmation-modal";

const useStyles = makeStyles((th) => ({
  wrapper: {
    width: "100%",
    height: "calc(100% - 152px)",
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
  },
  inputs: {
    width: "100%",
    borderRadius: 0,
    border: th.borders[2],
    paddingTop: th.spacing(0.5),
    paddingBottom: th.spacing(0.5),
    paddingLeft: th.spacing(2),
  },
  inputLabel: {
    fontSize: th.typography.body1.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
  },
  inputError: {
    color: th.palette.red,
    borderColor: th.palette.red,
  },
  locationError: {
    border: `2px solid ${th.palette.red}`,
  },
  selects: {
    width: "50%",
    "& > div": {
      width: "100%",
    },
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  button: {
    width: 190,
    height: 42,
  },
  backdrop: {
    zIndex: th.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
    color: th.palette.text.primary,
    marginTop: th.spacing(2),
  },
  selectMargin: {
    marginTop: 0,
  },
}));

const INITIAL_INPUT_STATUS = {
  asset: "initial",
  level: "initial",
  location: "initial",
};

const Location = ({
  maps,
  assets = [],
  status = "initial",
  adspace = {},
  nextStep = () => {},
  onSetOpenDelete,
  isNew,
  openModalConfirmation,
}) => {
  const { i18n } = useLingui();

  const [values, setValues] = useState(adspace);
  const [mapCenter, setMapCenter] = useState();
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const [inputStatus, setInputStatus] = useState({
    asset: "initial",
    level: "initial",
    location: "initial",
  });
  const [paintMarker, setPaintMarker] = useState(false);

  const classes = useStyles({ ...theme, inputStatus });
  const mapRef = useRef();
  const markerRef = useRef();
  const { navigate } = useNavigate();

  const handleSubmit = () => {
    setInputStatus(INITIAL_INPUT_STATUS);
    let canContinue = true;
    if (values.asset_id < 0) {
      setInputStatus((sts) => ({ ...sts, asset: "error" }));
      canContinue = false;
    }
    if (values.level === "") {
      setInputStatus((sts) => ({ ...sts, level: "error" }));
      canContinue = false;
    }
    if (!isLocationSelected) {
      setInputStatus((sts) => ({ ...sts, location: "error" }));
      canContinue = false;
    }
    if (canContinue) {
      nextStep(values);
    }
  };

  const setCurrentMarker = (map, latitude, longitude) => {
    if (!maps) return;

    const newMarker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
    });

    if (markerRef.current) markerRef.current.marker.setMap(null);

    markerRef.current = {
      longitude,
      latitude,
      marker: newMarker,
    };
  };
  const handleClickMap = (map, evt) => {
    const latitude = evt.latLng.lat();
    const longitude = evt.latLng.lng();
    setIsLocationSelected(true);
    setCurrentMarker(map, latitude, longitude);
    setValues((v) => ({ ...v, latitude, longitude }));
  };

  const handleOnChange =
    (field) =>
    ({ target }) => {
      setValues((state) => ({
        ...state,
        [field]: target.value,
      }));
    };

  const handleOnChangeAsset =
    (field) =>
    ({ target }) => {
      const asset = assets.find((a) => a.id === target.value);
      setMapCenter({
        lat: parseFloat(asset.latitude),
        lng: parseFloat(asset.longitude),
      });
      setValues((state) => ({
        ...state,
        [field]: target.value,
      }));
    };

  const checkInputError = (input) => {
    return inputStatus[input] === "error" ? classes.inputError : "";
  };

  useEffect(() => {
    if (
      adspace.id > 0 &&
      mapRef.current &&
      !isNaN(parseFloat(adspace.latitude)) &&
      !isNaN(parseFloat(adspace.longitude))
    ) {
      if (!paintMarker) {
        setMapCenter({
          lat: parseFloat(adspace.latitude),
          lng: parseFloat(adspace.longitude),
        });
        setPaintMarker(true);
      }
      setTimeout(() => {
        setCurrentMarker(mapRef.current, parseFloat(adspace.latitude), parseFloat(adspace.longitude));
        setIsLocationSelected(true);
      }, 0);
    }
  }, [adspace.id]);

  useEffect(() => {
    setValues(adspace);
  }, [adspace, assets]);

  const goBack = () => {
    navigate(`/administrator/adspaces/new/title`);
  };

  const somethingHasChanged = JSON.stringify(values) !== JSON.stringify(adspace);
  const handleResetChanges = () => setValues(adspace);
  const disableSubmit = status === "loading" || (!isNew && !somethingHasChanged);

  return (
    <Box className={classes.container}>
      <Backdrop className={classes.backdrop} open={status === "loading"}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.wrapper} px={2} sx={{ overflowY: "auto" }}>
        <Box pb={3}>
          <ValidatorForm onSubmit={() => {}}>
            <Box mb={2} className={classes.inputRow}>
              <Box className={classes.selects}>
                <Select
                  label={i18n._(t`Centro comercial`)}
                  validators={["required"]}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    className: `${classes.inputLabel} ${checkInputError("asset")}`,
                  }}
                  InputProps={{
                    className: `${classes.inputs} ${checkInputError("asset")}`,
                  }}
                  value={values.asset_id}
                  disabled={values.id > 0}
                  onChange={handleOnChangeAsset("asset_id")}
                  selectClassName={classes.selectMargin}
                >
                  {assets.map((u) => (
                    <MenuItem key={u.name} value={u.id} style={{ fontSize: 12 }}>
                      {i18n._(t`${u.name}`)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className={classes.selects} ml={2}>
                <Select
                  label={i18n._(t`Planta`)}
                  validators={["required"]}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    className: `${classes.inputLabel} ${checkInputError("level")}`,
                  }}
                  InputProps={{
                    className: `${classes.inputs} ${checkInputError("level")}`,
                  }}
                  value={values.level}
                  onChange={handleOnChange("level")}
                  selectClassName={classes.selectMargin}
                >
                  {config.LEVEL_NAMES?.length
                    ? config.LEVEL_NAMES.map((lvl) => (
                        <MenuItem key={lvl} value={lvl} style={{ fontSize: 12 }}>
                          {i18n._(t`${lvl}`)}
                        </MenuItem>
                      ))
                    : ["-1", "0", "1", "2"].map((lvl) => (
                        <MenuItem key={lvl} value={lvl} style={{ fontSize: 12 }}>
                          {lvl}
                        </MenuItem>
                      ))}
                </Select>
              </Box>
            </Box>
          </ValidatorForm>
          <Box>
            <Typography
              variant="subtitle1"
              style={{
                color: inputStatus.location === "error" ? theme.palette.red : theme.palette.black,
              }}
            >
              <Trans>Ubicación</Trans>
            </Typography>
          </Box>
          <Box mt={1} mb={1}>
            <Typography variant="body1">
              <Trans>
                Para seleccionar la ubicación del espacio en el centro comercial, simplemente haga click en el mapa.
              </Trans>
            </Typography>
          </Box>
          <Box className={inputStatus.location === "error" ? classes.locationError : ""}>
            <Map center={mapCenter} mapRef={mapRef} height="278px" width="100%" onClickMap={handleClickMap} zoom={18} />
          </Box>
        </Box>
      </Box>
      <AdSpaceStepButtons
        labelSubmit={!isNew ? <Trans>Guardar</Trans> : <Trans>Crear</Trans>}
        handleSubmit={handleSubmit}
        disableSubmit={disableSubmit}
        disableContinue={status === "loading"}
        onSetOpenDelete={onSetOpenDelete}
        showDeleteAdSpace={!isNew}
        hasResetChanges={!isNew}
        onResetChanges={handleResetChanges}
        disableResetChanges={!somethingHasChanged}
        hasBack={isNew}
        handleBack={goBack}
      />
      <ConfirmationModal modalData={openModalConfirmation} />
    </Box>
  );
};

export default Location;
