import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdSpaceTypesThunk, postAdSpaceThunk } from "../../redux/actions/adspaces-actions";
import CreateSpaceWizard from "../../components/create-space-wizard";
import { postSpaceThunk } from "../../redux/actions/space-actions";

export default ({ open = false, onClose = () => {}, isAdSpace = false }) => {
  const managedAssets = useSelector((state) => state.user.data.managedAssets);
  const spaceSubTypes = useSelector((state) => state.misc.spaceSubTypes);
  const spaceTypes = useSelector((state) => state.misc.spaceTypes);
  const spaceStatus = useSelector((state) => state.listing.status);
  const maps = useSelector((state) => state.googleMaps.maps);
  const assets = useSelector((state) => state.misc.assets);
  const space = useSelector((state) => state.listing.data);
  const id = useSelector((state) => state.listing.data.id);
  const user = useSelector((state) => state.user.data.id);
  const adSpaceStatus = useSelector((state) => state.adspaces.adSpaceStatus);
  const adSpaceTypes = useSelector((state) => state.adspaces.adSpaceTypes);
  const adSpaceId = useSelector((state) => state.adspaces.adSpace.id);
  const adSpace = useSelector((state) => state.adspaces.adSpace);

  const dispatch = useDispatch();

  const submitHandler = async (data, isSpace = true) => {
    const format = { ...data };
    if (isSpace) {
      format.space_sub_type_id = spaceTypes[0].id;
      format.space_type_id = spaceSubTypes[0].id;
      dispatch(postSpaceThunk(format, user));
      return;
    }
    [format.adspace_type] = adSpaceTypes;
    format.asset_id = format.asset.id;
    format.asset = undefined;
    format.id = undefined;
    dispatch(postAdSpaceThunk(format));
  };

  const filteredAssets =
    assets?.length && managedAssets?.length ? assets.filter((a) => managedAssets.find((m) => m.id === a.id)) : [];

  useEffect(() => isAdSpace && dispatch(getAdSpaceTypesThunk()), [isAdSpace, dispatch]);

  return (
    <CreateSpaceWizard
      assets={!isAdSpace ? filteredAssets : managedAssets.length ? managedAssets : []}
      initialValues={!isAdSpace ? space : adSpace}
      status={!isAdSpace ? spaceStatus : adSpaceStatus}
      open={open}
      maps={maps}
      id={!isAdSpace ? id : adSpaceId}
      onSubmit={submitHandler}
      onClose={onClose}
      isAdSpace={isAdSpace}
    />
  );
};
