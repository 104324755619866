import React, { useRef, useState } from "react";
import { Checkbox, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DragableItem = ({ id, name, labelId, checked, onClick }) => {
  const [isDragging, setIsDragging] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  const dragTimeout = useRef(null);

  const handleMouseDown = () => {
    dragTimeout.current = setTimeout(() => {
      setIsDragging(true);
    }, 200);
  };

  const handleMouseUp = () => {
    clearTimeout(dragTimeout.current);
    if (!isDragging) {
      onClick(id);
    }
    setIsDragging(false);
  };

  return (
    <ListItem
      key={id}
      innerRef={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      disablePadding
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      role={undefined}
      button
    >
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={name} />
    </ListItem>
  );
};

export default DragableItem;
