import { makeStyles } from "@material-ui/core/styles";
import { Notifications as NotificationsIcon } from "@material-ui/icons";
import numbro from "numbro";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "/src/components/link";
import Tooltip from "@material-ui/core/Tooltip";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
// theme
import theme from "../../theme";
import TableDataDownload from "../table-data-download";
import Table from "../table";
import TableFooter from "../table-footer";
import ConsultsOwnerToolbar from "../../containers/consults-owner/consults-toolbar";
import { isBefore, formatDateUtc, formatDate } from "../../utils/dateUtils";
import { convertToVirtualState } from "../../utils";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.black,
    fontWeight: "bold",
  },
  headerTable: {
    display: "flex",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  notification: {
    color: theme.palette.primary.main,
    height: 14,
    width: 14,
    position: "relative",
    top: 2,
  },
  retailIcon: {
    width: 120,
    height: 120,
    opacity: 0.7,
  },
}));

function ConsultsOwner({
  consults = [],
  canCreateBooking,
  pagination,
  status,
  isFirstLoadCompleted,
  mainState,
  onTableChange,
  onRowClick,
  selectedRow,
  onClickAdd,
  onConfirmPendingTransactions,
  onChangePage,
  downloadTableData,
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [width, setWidth] = useState("500px");
  const [columnShown, setColumnShown] = useState({
    notification: true,
    id: true,
    created_at: false,
    state: true,
    start: true,
    end: false,
    daycount: true,
    price: true,
    agent: false,
    client: false,
    company: true,
    brand: true,
    project: false,
    space: true,
    asset: false,
  });

  const resize = () => {
    const container = document.getElementById("routes-container");
    if (container) {
      setWidth(container.clientWidth - 50);
    }
  };

  useEffect(() => {
    resize();
    window.onload = resize;
    window.onresize = () => {
      setWidth(300);
      resize();
    };
    const columns = localStorage.getItem("consultsColumns");
    if (columns) {
      setColumnShown(JSON.parse(columns));
    } else {
      localStorage.setItem("consultsColumns", JSON.stringify(columnShown));
    }
    onTableChange("changePage", { page: 0 });
  }, []);

  const handleTableData = () => {
    const data = consults.map((c) => ({
      notification: isBefore(c.user_last_seen, c.updated_at),
      created_at: formatDate(c.created_at, "DD/MM/YYYY HH:mm:ss"),
      state: <Trans id={convertToVirtualState(c.status, c.start, c.end, c.confirmed_at)} />,
      id: c.id,
      start: formatDateUtc(c.start, "DD/MM/YYYY"),
      end: formatDateUtc(c.end, "DD/MM/YYYY"),
      daycount: parseInt(c.days_count), // getDays(c.start, c.end),
      price: numbro(parseFloat(c.price)).formatCurrency(),
      agent: c.agent?.name,
      client: c.author?.name,
      company: c.author?.profile?.company?.name,
      brand: c.brand_name,
      project: c.project_name,
      spaceName: c.space?.title,
      asset: c.asset?.name,
    }));

    return data;
  };

  const handleSpaceCell = (consult) => {
    let cellText = "";

    const { spaces = [], adspaces = [] } = consult || {};

    if (spaces.length + adspaces.length > 1) {
      cellText = `${i18n._(t`Multiespacio`)} (${spaces.length + adspaces.length}) `;
    } else if (adspaces.length > 0) {
      cellText = `[${adspaces[0].title}]`;
    } else if (spaces.length > 0) {
      cellText = spaces[0].title;
    }

    const handleTooltip = () => {
      if (consult.adspaces.length > 0) {
        return (
          <>
            {consult.space && (
              <>
                <span style={{ textDecoration: "underline" }}>{i18n._(t`Espacio`)}</span>
                <p>{consult.space.title}</p>
              </>
            )}
            <span style={{ textDecoration: "underline" }}>{i18n._(t`Espacios publicitarios`)}</span>
            {consult.adspaces.map((ad) => {
              return <p key={ad.id}>{ad.title}</p>;
            })}
          </>
        );
      }
      return <p>{i18n._(t`No hay espacio publicitario`)}</p>;
    };

    return (
      <Box>
        <Tooltip arrow title={<span>{handleTooltip()}</span>}>
          <span>{cellText}</span>
        </Tooltip>
      </Box>
    );
  };

  const handleShowHideColumn = (col, action) => {
    if (action === "add") {
      setColumnShown({ ...columnShown, [col]: true });
      localStorage.removeItem("consultsColumns");
      localStorage.setItem("consultsColumns", JSON.stringify({ ...columnShown, [col]: true }));
    } else if (action === "remove") {
      setColumnShown({ ...columnShown, [col]: false });
      localStorage.removeItem("consultsColumns");
      localStorage.setItem("consultsColumns", JSON.stringify({ ...columnShown, [col]: false }));
    }
  };

  const columnOptions = {
    setCellProps: (value) => {
      return {
        style: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          whiteSpace: "nowrap",
          textAlign: "center",
        },
      };
    },
    setCellHeaderProps: (value) => {
      return {
        style: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          whiteSpace: "nowrap",
          textAlign: "center",
        },
      };
    },
  };

  const columns = [
    {
      label: i18n._(t`Notificación`),
      name: "notification",
      options: {
        display: columnShown.notification,
        customBodyRenderLite: (dataIndex) => {
          const isFollower = consults[dataIndex].userIsFollower;
          const lastSeen = consults[dataIndex].user_last_seen;
          const consultUpdatedAt = consults[dataIndex].updated_at;

          return isFollower && (!lastSeen || isBefore(lastSeen, consultUpdatedAt)) ? (
            <NotificationsIcon height={14} className={classes.notification} />
          ) : (
            <></>
          );
        },
        customHeadRender: (p) => (
          <th
            key={JSON.stringify(p)}
            className="MuiTableCell-root MuiTableCell-head"
            style={{ backgroundColor: theme.palette.white }}
          />
        ),
        setCellHeaderProps: (value) => {
          return {
            style: {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            },
          };
        },
      },
    },
    {
      label: "ID",
      name: "id",
      options: { ...columnOptions, display: columnShown.id, sort: true },
    },
    {
      label: i18n._(t`Creada`),
      name: "created_at",
      options: {
        ...columnOptions,
        display: columnShown.created_at,
        sort: false,
      },
    },
    {
      label: i18n._(t`Estado`),
      name: "state",
      options: {
        ...columnOptions,
        display: columnShown.state,
        sort: false,
      },
    },
    {
      label: i18n._(t`Comienzo`),
      name: "start",
      options: { ...columnOptions, display: columnShown.start, sort: true },
    },
    {
      label: i18n._(t`Final`),
      name: "end",
      options: {
        ...columnOptions,
        display: columnShown.end,
        sort: true,
      },
    },
    {
      label: i18n._(t`Duración`),
      name: "daycount",
      options: { ...columnOptions, display: columnShown.daycount, sort: false },
    },
    {
      label: i18n._(t`Precio`),
      name: "price",
      options: { ...columnOptions, display: columnShown.price, sort: true },
    },
    {
      label: i18n._(t`Gestor`),
      name: "agent",
      options: {
        ...columnOptions,
        display: columnShown.agent,
        sort: false,
      },
    },
    {
      label: i18n._(t`Usuario`),
      name: "client",
      options: { ...columnOptions, display: columnShown.client, sort: false },
    },
    {
      label: i18n._(t`Empresa`),
      name: "company",
      options: { ...columnOptions, display: columnShown.company, sort: false },
    },
    {
      label: i18n._(t`Marca`),
      name: "brand",
      options: { ...columnOptions, display: columnShown.brand, sort: true },
    },
    {
      label: i18n._(t`Proyecto`),
      name: "project",
      options: {
        ...columnOptions,
        display: columnShown.project,
        sort: true,
      },
    },
    {
      label: i18n._(t`Espacio`),
      name: "spaceName",
      options: {
        ...columnOptions,
        sort: false,
        display: columnShown.space,
        setCellProps: () => ({
          style: {
            maxWidth: "150px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        }),
        customBodyRenderLite: (dataIndex) => handleSpaceCell(consults[dataIndex]),
      },
    },
    {
      label: i18n._(t`Centro Comercial`),
      name: "asset",
      options: {
        ...columnOptions,
        sort: false,
        display: columnShown.asset,
      },
    },
  ];

  const handleOpenDownloadModal = () => {
    setOpenDownloadModal(true);
    return false;
  };

  const FooterActionButtons = () => (
    <Link to={"/administrator/spaces/occupation/booking/spaces/all"}>
      <Button className={classes.filters} variant={"contained"}>
        <Trans>Ocupación de espacios</Trans>
      </Button>
    </Link>
  );

  return (
    <Box position="relative" pl={2} pr={2} width="100%" height="100%">
      <TableDataDownload
        open={openDownloadModal}
        onClose={() => setOpenDownloadModal(false)}
        onDownload={downloadTableData}
      />
      <Table
        data={handleTableData()}
        columns={columns}
        isLoading={status === "loading"}
        selectedRow={selectedRow}
        onDownload={handleOpenDownloadModal}
        options={{
          onTableChange,
          onRowClick,
          fixedSelectColumn: true,
          responsive: "simple",
          onViewColumnsChange: handleShowHideColumn,
          customToolbar: () => {
            return (
              <ConsultsOwnerToolbar
                onTableChange={onTableChange}
                mainState={mainState}
                isFirstLoadCompleted={isFirstLoadCompleted}
              />
            );
          },
          customFooter: () => {
            return (
              <TableFooter
                pagination={pagination}
                onChangePage={onChangePage}
                canCreate={canCreateBooking}
                primaryButton={i18n._(t`Crear nueva`)}
                onPrimaryButton={onClickAdd}
                secondaryButton={i18n._(t`Confirmar transacciones`)}
                onSecondaryButton={onConfirmPendingTransactions}
                actionButtons={FooterActionButtons}
                showActionButtons={Boolean(onClickAdd)}
              />
            );
          },
        }}
      />
    </Box>
  );
}

ConsultsOwner.propTypes = {
  consults: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};

export default ConsultsOwner;
