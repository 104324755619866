import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { CONSULT_STATUS } from "../../redux/reducers";

import ListingFormContainer from "../../containers/listing-form";
import AdSpaceDashboard from "../../containers/ad-spaces-form";
import ConsultsOwner from "../../containers/consults-owner";
import SpacesOwner from "../../containers/spaces-owner";
import AdSpacesOwner from "../../containers/ad-spaces-owner";
import Landlords from "../../containers/landlords";
import OwnerAssets from "../../containers/owner-assets";
import UsersReport from "../../containers/users-report";
import AssetForm from "../../containers/asset-form";
import CustomersOwner from "../../containers/customers-owner";
import ImportsList from "../../containers/imports-list";
import AdminMenu from "../../containers/owner-dashboard/admin-menu";
import HomeOwner from "../../containers/home-owner";
import SpaceTimelineVacancy from "../../containers/timeline-vacancy/spaces-timeline";
import SideMenu from "../../containers/owner-dashboard/side-menu";
import LocalizedRedirect from "../../containers/localized-redirect";
import DashboardTitle from "../../containers/owner-dashboard/dashboard-title";
// theme
import theme from "../../theme";

// config
import InvoicingReportContainer from "../../containers/invoicing-report";
import BookingInvoicingReportContainer from "../../containers/invoicing-report/booking-invoice-report";
import PerformanceAnalyzerContainer from "../../containers/performance-analyzer";
import PrivateRoute from "../../private-route";
import CustemersOwnerDetail from "../../containers/consults-owner/custemers-detail";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    color: "white",
    background: "black",
    height: "100vh",
    overflow: "hidden",
  },
  logo: {
    height: 70,
    width: "auto",
    marginTop: theme.spacing(2),
  },
  menu: {
    flex: 1,
    maxWidth: 240,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    paddingRight: 0,
  },
  menuItems: {
    width: 216,
    marginTop: theme.spacing(3),
  },
  item: {
    display: "flex",
    color: "white",
    height: 45,
    width: 216,
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    position: "relative",
    background: ({ active }) => (active ? theme.palette.primary.main : "transparent"),
    borderBottom: "1px solid white",
    transition: "0.2s background ease-in",
    "&:hover": {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  rightContainer: {
    flex: 2,
    height: "100%",
    margin: theme.spacing(2),
    width: 0,
  },
  navbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    flex: 1,
    height: 64,
    background: "white",
    color: theme.palette.secondary.main,
    margin: theme.spacing(2),
    marginLeft: 0,
    position: "relative",
    borderRadius: 10,
    borderTopLeftRadius: 0,
  },
  topNavbarLine: {
    background: theme.palette.primary.main,
    height: 5,
    width: 200,
    position: "absolute",
    top: 0,
  },
  routesContainer: {
    position: "relative",
    background: theme.palette.white,
    height: "calc(100% - 130px)",
    overflow: "auto",
    borderRadius: 10,
    borderTopLeftRadius: 0,
    "& > div": {
      // this is for the div created by the private router
      height: "100%",
    },
  },
  version: {
    flex: 5,
    display: "flex",
    alignItems: "flex-end",
  },
  notificator: {
    background: ({ active }) => (active ? theme.palette.white : theme.palette.primary.main),
    width: 5,
    height: 5,
    borderRadius: "50%",
    position: "absolute",
    right: 10,
  },
  listingForm: {
    height: "100%",
  },
}));

const OwnerDashboard = () => {
  const classes = useStyles({
    ...theme,
  });

  return (
    <Box className={classes.container}>
      <SideMenu />
      <Box className={classes.rightContainer}>
        <Box className={classes.navbar}>
          <Box className={classes.topNavbarLine} />
          <DashboardTitle />
          <Box display="flex" alignItems="center">
            <AdminMenu />
          </Box>
        </Box>
        <Box id="routes-container" className={classes.routesContainer}>
          <PrivateRoute basePath="/administrator">
            <HomeOwner path="/home" />
            <ConsultsOwner path="/requests/:booking" consultType="requests" mainState={CONSULT_STATUS.REQUESTED} />
            <ConsultsOwner path="bookings/:booking" consultType="bookings" mainState={CONSULT_STATUS.TO_BE_STARTED} />
            <InvoicingReportContainer path="/invoicing" />
            <BookingInvoicingReportContainer path="/invoicing/bookings" />
            <PerformanceAnalyzerContainer path="/performance" />
            <SpaceTimelineVacancy path="/:spaceType/occupation/booking/:spaceType/:booking" />
            <SpacesOwner path="/spaces" />
            <AdSpacesOwner path="/adspaces" />
            <CustomersOwner path="/clients" />
            <ImportsList path="/imports" />
            <CustemersOwnerDetail path="/clients/:custumerId/:stepName/:booking" />
            <Landlords path="/assets-landlords/landlords" />
            <OwnerAssets path="/assets-landlords/assets" />
            <UsersReport path="/assets-landlords/users-report" />
            <AssetForm path="/assets-landlords/assets/:assetId/:stepName" />
            <LocalizedRedirect
              noThrow
              path="assets-landlords/assets/:assetId"
              to="/administrator/assets-landlords/assets/:assetId/information"
            />
            <LocalizedRedirect noThrow path="/bookings" to="/administrator/bookings/all" />
            <LocalizedRedirect noThrow path="/requests" to="/administrator/requests/all" />
            <LocalizedRedirect noThrow path="/assets-landlords" to="/administrator/assets-landlords/assets" />
          </PrivateRoute>
          <PrivateRoute className={classes.listingForm} basePath="/administrator">
            <ListingFormContainer path="/spaces/:spaceId/:stepName" />
            <ListingFormContainer path="/spaces/:spaceId" />
            <AdSpaceDashboard path="/adspaces/:adSpaceId/:stepName" />
          </PrivateRoute>
        </Box>
      </Box>
    </Box>
  );
};

export default OwnerDashboard;
