import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import theme from "../../theme";
import DecideDialog from "../decide-dialog";
import OwnerModal from "../owner-modal";
import config from "../../config";
import TextField from "../text-field";
import Select from "../select/select-validator";
import { useNavigate } from "../../hooks/navigation";
import MapSelection from "./MapSelection";
import ActionResultModal from "../action-result-modal";

const VALIDATION_FIELDS = {
  title: false,
  description: false,
  asset: false,
  level: false,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  textFieldsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  labelDescription: {
    transform: "translate(0, 15px) scale(1)",
    paddingBottom: 0,
  },
  labelInput: {
    transform: "translate(0, -30px) scale(1)!important",
    fontFamily: theme.typography.subtitle2.fontFamily,
    fontSize: 14,
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
  },
  inputs: {
    width: "100%",
    borderRadius: 0,
    border: theme.borders[2],
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
  inputLabel: {
    fontSize: theme.typography.body2.fontSize,
    transform: "translate(0, -5px) scale(1)",
    fontWeight: "bold",
  },
  inputError: {
    color: theme.palette.red,
    borderColor: theme.palette.red,
  },
  selectMargin: {
    marginTop: 0,
  },
}));

export default ({
  open = false,
  assets,
  status,
  id,
  maps,
  initialValues,
  isAdSpace,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const [validationFields, setValidationFields] = useState(VALIDATION_FIELDS);
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [validationStatus, setValidationStatus] = useState(false);
  const [initialMapCenter, setInitialMapCenter] = useState(null);
  const [succesModalOpen, setSuccessModalOpen] = useState(false);
  const [values, setValues] = useState({ ...initialValues });
  const [expandedPanel, setExpandedPanel] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles({ ...theme });
  const { navigate } = useNavigate();
  const { i18n } = useLingui();

  const VARIABLE_STRINGS = useMemo(
    () => ({
      SPACE_CREATION_TITLE: i18n._(t`Crear nuevo espacio`),
      SPACE_TITLE_LABEL: i18n._(t`No está permitido utilizar el nombre real.`),
      SPACE_TITLE_PLACEHOLDER: i18n._(t`Ej.: Tienda boutique ubicada en Malasaña`),
      SPACE_DESCRIPTION_LABEL: i18n._(t`Describe brevemente qué es lo que hace único a tu espacio`),
      SPACE_DESCRIPTION_PLACEHOLDER: i18n._(
        t`Ej.: Tienda recién reformada perfecta para hacer cualquier tipo de acción comercial, situada en una zona de alta afluencia de público`,
      ),
      ADSPACE_CREATION_TITLE: i18n._(t`Crear nuevo espacio publicitario`),
      ADSPACE_TITLE_LABEL: i18n._(t`(Este es el título que se va a mostrar a los clientes)`),
      ADSPACE_TITLE_PLACEHOLDER: i18n._(t`Escalera mecánica P2. B`),
      ADSPACE_DESCRIPTION_LABEL: i18n._(t`Descripción`),
      ADSPACE_DESCRIPTION_PLACEHOLDER: i18n._(t`Gran exposición...`),
    }),
    [i18n],
  );

  const handlePanelExpansion = (panel) => setExpandedPanel(panel);

  const resetComponent = useCallback(() => {
    setValues({ ...initialValues });
    setValidationFields(VALIDATION_FIELDS);
    setConfirmCloseModal(false);
    setExpandedPanel(1);
    setSubmitted(false);
  }, [initialValues]);

  const handleClose = () => {
    resetComponent();
    onClose();
  };

  const handleListingSuccess = () => {
    navigate(!isAdSpace ? `/administrator/spaces/${id}/space-type` : `/administrator/adspaces/${id}/information`);
  };

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((state) => ({ ...state, [field]: target.value }));
      setValidationFields((state) => ({ ...state, [field]: target.value.length }));
    };

  const getAsset = (assetId) => assets.find((a) => a.id === assetId);

  const handleChangeAsset = ({ target }) => {
    const asset = getAsset(target.value);
    if (!asset) {
      setValidationFields((state) => ({ ...state, asset: false }));
      return;
    }
    setValues((state) => ({ ...state, asset, latitude: asset.latitude, longitude: asset.longitude }));
    setValidationFields((state) => ({ ...state, asset: true }));
    setInitialMapCenter({ lat: asset.latitude, lng: asset.longitude });
  };

  const handleChangeLevel = ({ target }) => {
    setValues((state) => ({ ...state, level: target.value }));
    setValidationFields((state) => ({ ...state, level: true }));
  };

  const handleMapClick = (location) => {
    setValues((state) => ({ ...state, latitude: location.latitude, longitude: location.longitude }));
  };

  const handleSubmit = () => {
    onSubmit({ ...values }, !isAdSpace);
    setSubmitted(true);
  };

  useEffect(() => {
    const isValid = Object.values(validationFields).every((v) => v);
    setValidationStatus(isValid);
  }, [validationFields]);

  useEffect(() => {
    if (submitted && status === "success") setSuccessModalOpen(true);
  }, [submitted, status]);

  useEffect(() => resetComponent(), [open, resetComponent]);

  return (
    <>
      <OwnerModal open={open} onClose={() => setConfirmCloseModal(true)} width={600}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h1">
            {isAdSpace ? VARIABLE_STRINGS.ADSPACE_CREATION_TITLE : VARIABLE_STRINGS.SPACE_CREATION_TITLE}
          </Typography>
        </Box>
        <Box mt={1} mb={1} style={{ borderBottom: "1px solid black" }} />
        <ValidatorForm className={classes.container} onSubmit={handleSubmit}>
          <Accordion expanded={expandedPanel === 1} onChange={() => handlePanelExpansion(1)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h2">
                <Trans>Descripción</Trans>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container className={classes.textFieldsContainer} spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    labelClassNames={classes.labelDescription}
                    labelInputClassNames={classes.labelInput}
                    label={`${i18n._(t`¿Cuál es el título de tu espacio?`)}`}
                    description={isAdSpace ? VARIABLE_STRINGS.ADSPACE_TITLE_LABEL : VARIABLE_STRINGS.SPACE_TITLE_LABEL}
                    placeholder={
                      isAdSpace ? VARIABLE_STRINGS.ADSPACE_TITLE_PLACEHOLDER : VARIABLE_STRINGS.SPACE_TITLE_PLACEHOLDER
                    }
                    validators={["required"]}
                    errorMessages={[""]}
                    value={values.title}
                    onChange={handleInputChange("title")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    labelClassNames={classes.label}
                    labelInputClassNames={classes.labelInput}
                    multiline
                    rows={6}
                    description={
                      isAdSpace ? VARIABLE_STRINGS.ADSPACE_DESCRIPTION_LABEL : VARIABLE_STRINGS.SPACE_DESCRIPTION_LABEL
                    }
                    placeholder={
                      isAdSpace
                        ? VARIABLE_STRINGS.ADSPACE_DESCRIPTION_PLACEHOLDER
                        : VARIABLE_STRINGS.SPACE_DESCRIPTION_PLACEHOLDER
                    }
                    validators={["required"]}
                    errorMessages={[""]}
                    value={values.description}
                    onChange={handleInputChange("description")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {assets?.length > 0 && (
            <Accordion expanded={expandedPanel === 2} onChange={() => handlePanelExpansion(2)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h2">
                  <Trans>Ubicación</Trans>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} pt={2}>
                  <Grid item xs={12}>
                    <Select
                      label={`${i18n._(t`Centro Comercial`)}`}
                      placeholder={`${i18n._(t`Ej: Chamberi`)}`}
                      InputLabelProps={{ className: classes.inputLabel }}
                      InputProps={{ className: classes.inputs }}
                      value={values.asset?.id || ""}
                      onChange={handleChangeAsset}
                      fullWidth
                      selectClassName={classes.selectMargin}
                    >
                      {assets.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      label={`${i18n._(t`Planta`)}`}
                      placeholder={`${i18n._(t`Ej: Primer planta`)}`}
                      value={values.level || ""}
                      InputLabelProps={{ className: classes.inputLabel }}
                      InputProps={{ className: classes.inputs }}
                      onChange={handleChangeLevel}
                      fullWidth
                      selectClassName={classes.selectMargin}
                    >
                      {(config.LEVEL_NAMES?.length ? config.LEVEL_NAMES : ["-1", "0", "1", "2"]).map((lvl) => (
                        <MenuItem key={lvl} value={lvl} style={{ fontSize: 12 }}>
                          {i18n._(t`${lvl}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <MapSelection maps={maps} initialCenter={initialMapCenter} onSelection={handleMapClick} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          <Box display="flex" flexDirection="row" justifyContent="flex-end" px={2} mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!validationStatus || submitted}
            >
              <Trans>Crear</Trans>
            </Button>
          </Box>
        </ValidatorForm>
      </OwnerModal>
      <DecideDialog
        open={confirmCloseModal}
        onAccept={handleClose}
        title={<Trans>Existen cambios sin guardar</Trans>}
        desc={<Trans>¿Estás seguro de que quieres salir sin guardar?</Trans>}
        onClose={() => setConfirmCloseModal(false)}
      />
      <ActionResultModal
        isOpen={succesModalOpen}
        onClose={handleListingSuccess}
        status="success"
        successMessage={`${i18n._(isAdSpace ? t`El espacio publicitario` : t`El espacio`)} "${values.title}" ${i18n._(
          t`ha sido creado exitosamente.`,
        )}`}
      />
    </>
  );
};
