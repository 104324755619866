import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import {
  setConsultsOwnerState,
  getConsultsOwnerThunk,
  setSearchWords,
  setConsultsFilters,
  getConsultsFiltersThunk,
} from "../../redux/actions/consults-owner-actions";
import { CONSULT_STATUS } from "../../redux/reducers";
import ConsultsToolbar from "../../components/consults-owner/consults-toolbar";

// bookingState represents inprogress, confirmed.
const ConsultsToolbarContainer = ({ onTableChange, mainState, isFirstLoadCompleted }) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const userId = useSelector((state) => state.user.data.id);
  const filters = useSelector((state) => state.consultsOwner.filters);
  const filtersOptions = useSelector((state) => state.consultsOwner.filtersOptions);
  const bookingState = useSelector((state) => state.consultsOwner.bookingState);

  const { i18n } = useLingui();

  const REQUEST_VIRTUAL_STATES = {
    requested: i18n._(t`Solicitada`),
    pending: i18n._(t`Borradores`),
    accepted: i18n._(t`Aceptadas`),
    "unpaid-n-cancelled": i18n._(t`Canceladas`),
    "all-requests": i18n._(t`Todas`),
  };

  const BOOKING_VIRTUAL_STATES = {
    "to-be-started": i18n._(t`Reservas por comenzar`),
    done: i18n._(t`Reservas finalizadas`),
    "confirmed-n-cancelled": i18n._(t`Confirmadas y canceladas`),
    active: i18n._(t`Reservas activas`),
    "all-bookings": i18n._(t`Todas`),
  };

  const handleVirtualStates = (state) => {
    switch (state) {
      case CONSULT_STATUS.REQUESTED:
        return REQUEST_VIRTUAL_STATES;
      case CONSULT_STATUS.ACTIVE:
      default:
        return BOOKING_VIRTUAL_STATES;
    }
  };

  const handleFilterChange = (field) => (filter) => {
    dispatch(
      setConsultsFilters({
        ...filters,
        [field]: filter,
      }),
    );
  };

  const handleOnChangeVirtualState = (state) => {
    localStorage.setItem("BookingVirtualState", state);
    dispatch(setConsultsOwnerState(state));
    dispatch(getConsultsOwnerThunk({ state, search, filters, page: 1 }));
  };

  const handleOnSearch = (words) => {
    setSearch(words);
    dispatch(setSearchWords(words));
    dispatch(getConsultsOwnerThunk({ state: bookingState, search: words, filters, page: 1 }));
  };

  useEffect(() => {
    if (Object.keys(filters).length && bookingState && isFirstLoadCompleted) {
      dispatch(
        getConsultsOwnerThunk({
          state: bookingState,
          search,
          filters,
          page: 1,
        }),
      );
    }
  }, [filters]);

  useEffect(() => {
    dispatch(getConsultsFiltersThunk(userId));
  }, []);

  return (
    <ConsultsToolbar
      onTableChange={onTableChange}
      onSearch={handleOnSearch}
      filtersOptions={filtersOptions}
      onFilterChange={handleFilterChange}
      onVirtualStateChange={handleOnChangeVirtualState}
      virtualStates={handleVirtualStates(mainState)}
      bookingState={bookingState}
    />
  );
};

export default ConsultsToolbarContainer;
