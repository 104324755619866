import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  Paper,
  makeStyles,
  Container,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { DndContext, closestCorners } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Alert } from "@material-ui/lab";
import DragableItem from "./DragableItem";
import OwnerModal from "../owner-modal";
import theme from "../../theme-admin";
import ActionResultModal from "../action-result-modal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  paper: {
    maxHeight: 450,
    height: "100%",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    overflowY: "auto",
  },
  loadingMask: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.whiteMask,
  },
  listContainer: {
    overflowY: "auto",
  },
  warning: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(2),
    width: "100%",
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-end",
  },
}));

const FeaturedAssetsManager = ({
  open,
  status,
  assets = [],
  onResetStatus = () => {},
  onClose = () => {},
  onSave = () => {},
}) => {
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [availableAssets, setAvailableAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [initialState, setInitialState] = useState({});
  const [formIsValid, setFormIsValid] = useState(false);

  const classes = useStyles({ ...theme });

  useEffect(() => {
    if (open && assets.length) {
      const selected = [];
      const available = [];
      const orderMap = new Map();
      let nextOrder = 1;

      assets?.forEach((asset) => {
        if (asset.home_order) {
          selected.push({ ...asset });
        } else {
          available.push(asset);
        }
      });
      selected.sort((a, b) => a.home_order - b.home_order);
      selected.forEach((asset) => {
        while (orderMap.has(nextOrder)) {
          nextOrder += 1;
        }
        if (orderMap.has(asset.home_order)) {
          selected[selected.indexOf(asset)] = { ...asset, home_order: nextOrder };
        }
        orderMap.set(asset.home_order, asset.id);
      });
      setSelectedAssets(selected);
      setAvailableAssets(available);
      setInitialState({ selected, available });
    }
  }, [assets, open]);

  const handleCheck = (id) => {
    const isChecked = selectedAssets.some((asset) => asset.id === id);
    if (isChecked) {
      setAvailableAssets((prev) => [...prev, selectedAssets.find((item) => item.id === id)]);
      setSelectedAssets((prev) => prev.filter((item) => item.id !== id));
      return;
    }
    setSelectedAssets((prev) => [...prev, availableAssets.find((item) => item.id === id)]);
    setAvailableAssets((prev) => prev.filter((item) => item.id !== id));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = selectedAssets.findIndex((item) => item.id === active.id);
    const newIndex = selectedAssets.findIndex((item) => item.id === over.id);
    if (oldIndex === -1 || newIndex === -1) return;

    const newOrder = arrayMove(selectedAssets, oldIndex, newIndex);
    setSelectedAssets(newOrder);
  };

  const cancelHandler = () => onClose();
  const saveHandler = () => {
    const newOrder = new Map();
    selectedAssets.forEach((asset, index) => {
      newOrder.set(asset.id, index + 1);
    });
    return onSave(newOrder);
  };

  const resultModalCloseHandler = () => {
    setResultModalOpen(false);
    onResetStatus();
    if (status === "success") onClose();
  };

  useEffect(() => {
    if (open) {
      const isInitialState =
        JSON.stringify(initialState?.selected) === JSON.stringify(selectedAssets) &&
        JSON.stringify(initialState?.available) === JSON.stringify(availableAssets);
      setFormIsValid(!isInitialState && status === "initial");
    }
  }, [open, status, selectedAssets, availableAssets, initialState]);

  useEffect(() => {
    if (!["initial", "loading"].includes(status)) setResultModalOpen(true);
  }, [status]);

  return (
    <>
      <OwnerModal width={750} open={open} onClose={onClose}>
        <Container className={classes.container}>
          <Box className={classes.title}>
            <Typography variant="h1">
              <Trans>Featured Assets</Trans>
            </Typography>
            <Typography variant="subtitle1">
              <Trans>Selecciona los Centros que quieres mostrar en la página de inicio y ordénalos.</Trans>
            </Typography>
          </Box>
          <Grid container spacing={2} style={{ flex: 1 }}>
            <Grid item xs={6} style={{ display: "flex", flexDirection: "column" }}>
              <Paper className={classes.paper}>
                <Typography variant="h2" style={{ fontSize: "1rem" }}>
                  <Trans>Todos los Centros</Trans>
                </Typography>
                <List className={classes.listContainer}>
                  {!availableAssets.length && (
                    <Box className={classes.loadingMask}>
                      <CircularProgress size={24} />
                    </Box>
                  )}
                  {availableAssets?.map((item) => (
                    <ListItem key={item.id} button onClick={() => handleCheck(item.id)}>
                      <Checkbox checked={selectedAssets.some((asset) => asset.id === item.id)} />
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={6} style={{ display: "flex", flexDirection: "column" }}>
              <Paper className={classes.paper}>
                <Typography variant="h2" style={{ fontSize: "1rem" }}>
                  <Trans>Centros Seleccionados</Trans>
                </Typography>
                <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
                  <SortableContext items={selectedAssets.map((item) => item.id)} strategy={verticalListSortingStrategy}>
                    <List className={classes.listContainer}>
                      {!selectedAssets.length && (
                        <Box className={classes.loadingMask}>
                          <CircularProgress size={24} />
                        </Box>
                      )}
                      {selectedAssets?.map((item) => (
                        <DragableItem
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          onClick={() => handleCheck(item.id)}
                          checked
                        />
                      ))}
                    </List>
                  </SortableContext>
                </DndContext>
              </Paper>
            </Grid>
          </Grid>
          {selectedAssets.length > 9 && (
            <Box className={classes.warning}>
              <Alert variant="filled" severity="warning" color="warning">
                <Typography variant="subtitle1">
                  <Trans>
                    ¡Cuidado! Seleccionar mas de 9 centros puede generar una mala experiencia de usuario en dispositivos
                    móviles.
                  </Trans>
                </Typography>
              </Alert>
            </Box>
          )}
          <Box className={classes.footer}>
            <Button variant="contained" color="secondary" onClick={cancelHandler}>
              <Trans>Cancelar</Trans>
            </Button>
            <Button disabled={!formIsValid} variant="contained" color="primary" onClick={saveHandler}>
              <Trans>Guardar</Trans>
            </Button>
          </Box>
        </Container>
      </OwnerModal>
      <ActionResultModal
        isOpen={resultModalOpen}
        status={status}
        successMessage={i18n._(t`El orden de los centros fue actualizado exitosamente!`)}
        errorMessage={i18n._(t`Ocurrió un error al ordenar los centros comerciales. Por favor, inténtalo de nuevo.`)}
        onClose={resultModalCloseHandler}
      />
    </>
  );
};

export default FeaturedAssetsManager;
