import React, { useEffect, useRef, useState } from "react";
import { Trans } from "@lingui/macro";
import { Box, Typography } from "@material-ui/core";
import Map from "../google-maps";

export default ({ onSelection, maps, initialCenter }) => {
  const [mapCenter, setMapCenter] = useState(null);
  const [zoom, setZoom] = useState(16);
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  const setCurrentMarker = (map, latitude, longitude) => {
    if (!maps) return;
    const newMarker = new maps.Marker({ position: { lat: latitude, lng: longitude }, map });
    if (markerRef.current) markerRef.current.marker.setMap(null);
    markerRef.current = { longitude, latitude, marker: newMarker };
  };

  const handleMapClick = (map, evt) => {
    const latitude = evt.latLng.lat();
    const longitude = evt.latLng.lng();
    onSelection({ latitude, longitude });
    setCurrentMarker(map, latitude, longitude);
  };

  useEffect(() => {
    if (initialCenter) {
      setMapCenter(initialCenter);
      setZoom(16);
    }
  }, [initialCenter, mapCenter]);

  return (
    <Box width="100%">
      <Typography variant="body1">
        <Trans>
          Para seleccionar la ubicación del espacio en el centro comercial, simplemente haga click en el mapa.
        </Trans>
      </Typography>
      <Box>
        <Map height="275px" width="100%" center={mapCenter} mapRef={mapRef} onClickMap={handleMapClick} zoom={zoom} />
      </Box>
    </Box>
  );
};
