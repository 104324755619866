import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateBookingContractSuccess } from "../../redux/actions/consultation-actions";
import StepCreateComponent from "../../components/create-booking-wizard/step-6-create";
import api from "../../api";

function StepCreateContainer({ onNext, booking, onBack }) {
  const dispatch = useDispatch();
  const [resultCreate, setResultCreate] = useState({ error: false, status: "loading" });

  const handleUpdateContractContent = async (contract) => {
    try {
      const res = await api.updateBookingContract(contract);
      if (res.status === 200) {
        return res?.data?.payload;
      }
    } catch (e) {
      const error = new Error("FAILED_UPDATE_CONTRACT");
      error.responseData = e;
      throw error;
    }
  };

  const handleCreateBooking = async (payload, deferContract) => {
    try {
      const res = await api.createBookingTillPaid(payload, deferContract);
      if (res.status === 200) {
        return res?.data?.payload;
      }
    } catch (e) {
      const error = new Error("FAILED_CREATE_BOOKING");
      error.responseData = e;
      throw error;
    }
  };

  const handleCreateContractWithTemplate = async (bookingId, template) => {
    try {
      const res = await api.createBookingContractWithTemplate(bookingId, template);
      if (res.status === 200) {
        dispatch(updateBookingContractSuccess(res.data.payload));
        return res.data.payload;
      }
    } catch (e) {
      const error = new Error("FAILED_TEMPLATE_CONTRACT");
      error.responseData = e;
      throw error;
    }
  };

  const handleGeneratePreview = async (contractId) => {
    try {
      const res = await api.contractPreviewGenerate(contractId);
      if (res.status === 200) {
        return res?.data?.payload;
      }
    } catch (e) {
      const error = new Error("FAILED_GENERATE_PREVIEW_CONTRACT");
      error.responseData = e;
      throw error;
    }
  };

  const createBooking = async () => {
    const { contractMode, selectedContractModel, contractContent, ...values } = booking;

    if (contractMode === "text-contract") {
      try {
        const createdBooking = await handleCreateBooking({ ...values, contract: "https://empty.com" }, 1);
        const contract = await handleCreateContractWithTemplate(createdBooking.id, { id: selectedContractModel.id });

        if (contractContent) {
          await handleUpdateContractContent({ ...contract, content: contractContent });
        }

        if (contract.id) {
          await handleGeneratePreview(contract.id);
        }

        setResultCreate({
          status: "success",
          bookingId: createdBooking.id,
        });
      } catch (error) {
        setResultCreate({
          status: "error",
          error: error.message,
          message: error.responseData,
        });
      }
    } else {
      try {
        const createdBooking = await handleCreateBooking(values, 0);
        setResultCreate({
          status: "success",
          bookingId: createdBooking.id,
        });
      } catch (error) {
        setResultCreate({
          status: "error",
          error: error.message,
          message: error.responseData,
        });
      }
    }
  };

  useEffect(() => {
    createBooking();
  }, []);

  return <StepCreateComponent resultCreate={resultCreate} onBack={onBack} onNext={onNext} />;
}

export default StepCreateContainer;
