import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import { getCitiesAreasThunk } from "../../../redux/actions/misc-actions";
import LocationComponent from "../../../components/ad-spaces-form/location";
import { updateAdSpaceData, getAdSpaceThunk, getAdSpaceTypesThunk } from "../../../redux/actions/adspaces-actions";
import api from "../../../api";

const Location = ({ onSetOpenDelete }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const isNew = params.adSpaceId === "new";

  const adspace = useSelector((state) => state.adspaces.adSpace);
  const status = useSelector((state) => state.adspaces.adSpaceStatus);
  const userManagedAssets = useSelector((state) => state.user.data.managedAssets);
  const maps = useSelector((state) => state.googleMaps.maps);
  const adSpaceTypes = useSelector((state) => state.adspaces.adSpaceTypes);
  const [openModalConfirmation, setOpenModalConfirmation] = useState({ status: false, adSpaceId: "" });

  useEffect(() => {
    dispatch(getCitiesAreasThunk());
    if (isNew) {
      dispatch(getAdSpaceTypesThunk());
    }
    if (params.adSpaceId > 0) {
      dispatch(getAdSpaceThunk({ adSpaceId: params.adSpaceId }));
    }
  }, [params.adSpaceId]);

  const goToNextStep = async (adsValue) => {
    let adSpaceId = -1;
    if (params.adSpaceId === "new") {
      const res = await api.postAdSpace({
        asset_id: adsValue.asset_id,
        latitude: adsValue.latitude,
        longitude: adsValue.longitude,
        level: adsValue.level,
        title: adsValue.title,
        description: adsValue.description,
        fortnight_price: adsValue.fortnight_price,
        month_price: adsValue.month_price,
        adspace_type: adSpaceTypes[0],
      });
      if (res.status === 200) {
        adSpaceId = res.data.payload.id;
        dispatch(getAdSpaceThunk({ adSpaceId }));
        setOpenModalConfirmation({ status: true, title: adsValue.title, adSpaceId });
      }
    } else {
      adSpaceId = params.adSpaceId;
      await api.patchAdSpace(adSpaceId, adsValue);
      dispatch(updateAdSpaceData({ ...adsValue }));
    }
  };

  return (
    <LocationComponent
      maps={maps}
      assets={userManagedAssets}
      adspace={adspace}
      status={status}
      nextStep={goToNextStep}
      onSetOpenDelete={onSetOpenDelete}
      isNew={isNew}
      openModalConfirmation={openModalConfirmation}
    />
  );
};

export default Location;
