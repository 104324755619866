import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { t } from "@lingui/macro";
import { formatDateUtc, getNow, dateWithFormat } from "../../utils/dateUtils";
import api from "../../api";
import { useNavigate } from "/src/hooks/navigation";
import TimelineVacancy from "../../components/timeline-vacancy";
import { getBookingThunk, resetConsultation } from "../../redux/actions/consultation-actions";
import TimelineVacancyModalsContainer from "./modals";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";
import { SPACE_TYPE } from "../../utils";

// stub formatter to the timeline component
const occupationFormat = (occupation) =>
  Object.keys(occupation)?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: occupation[curr].map((block, i) => ({
        ...block,
        // this is needed to set a unique id. since its not comming from the backend
        id: i,
      })),
    }),
    {},
  );

const TimelineVacancyContainer = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();

  const bookingId = params.booking;
  const urlSpaceType = params.spaceType;
  const managedAssets = useSelector((state) => state.user.data.managedAssets);
  const [createBlockDatesStatus, setCreateBlockDatesStatus] = useState("initial");
  const [updateBlockDatesStatus, setUpdateBlockDatesStatus] = useState("initial");
  const [deleteBlockDatesStatus, setDeleteBlockDatesStatus] = useState("initial");

  const sortedManagedAssets = managedAssets.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const [spaceType, setSpaceType] = useState(SPACE_TYPE.SPACE);
  const [filters, setFilters] = useState({
    start: dateWithFormat(`${getNow().get("year")}/01/01`, "YYYY/MM/DD").toDate(),
    end: dateWithFormat(`${getNow().get("year")}/12/31`, "YYYY/MM/DD").toDate(),
    asset: {},
  });

  const [result, setResult] = useState({ status: "initial" });
  const [spaceBlockDates, setSpaceBlockDates] = useState([]);

  useEffect(() => {
    dispatch(setDashboardTitle(`${t`Espacios`} - ${t`Ocupación de espacios`}`));
    dispatch(resetAllAdSpaces());
    if (urlSpaceType === "espacios") {
      setSpaceType(SPACE_TYPE.SPACE);
    } else if (urlSpaceType === "adspaces") {
      setSpaceType(SPACE_TYPE.ADSPACE);
    }
  }, []);

  useEffect(() => {
    if (spaceType === SPACE_TYPE.SPACE && !bookingId) {
      navigate("/administrator/spaces/occupation/booking/spaces/all");
    } else if (spaceType === SPACE_TYPE.ADSPACE && !bookingId) {
      navigate("/administrator/adspaces/occupation/booking/adspaces/all");
    }
  }, [spaceType, bookingId]);

  useEffect(() => {
    if (managedAssets?.length) {
      setFilters((filters) => ({
        ...filters,
        asset: managedAssets[0],
      }));
    }
  }, [managedAssets]);

  useEffect(() => {
    if (bookingId && bookingId !== "all") {
      dispatch(getBookingThunk({ bookingId }));
    } else {
      dispatch(resetConsultation());
    }
  }, [bookingId]);

  const handleChangeFilters = (filters) => setFilters(filters);

  const dateFilterFormatted = (filters) => {
    return `${formatDateUtc(filters.start, "DD MMM YYYY")} - ${formatDateUtc(filters.end, "DD MMM YYYY")}`;
  };

  const getAssetBlockDates = async (id) => {
    let res;
    if (spaceType === SPACE_TYPE.SPACE) {
      res = await api.getSpaceBlockedDateRanges(id);
    } else if (spaceType === SPACE_TYPE.ADSPACE) {
      res = await api.getAdspaceBlockedDateRanges(id);
    }

    if (res.status === 200) {
      setSpaceBlockDates({
        status: res.status,
        data: res.data.payload.data,
      });
    } else {
      setSpaceBlockDates({
        status: "error",
        message: res.data,
      });
    }
  };

  const handleClickBlock = (block) => {
    if (block.space_id) {
      getAssetBlockDates(block.space_id);
    }

    if (block.adspace_id) {
      getAssetBlockDates(block.adspace_id);
    }

    if (block.booking_id) {
      navigate(`/administrator/${urlSpaceType}/occupation/booking/${urlSpaceType}/${block.booking_id}`);
    }
  };

  const getAssetSpaceOccupation = async (filters) => {
    const res = await api.getAssetSpaceOccupation(filters.asset.id, filters);

    if (res.status === 200) {
      const { spaces, occupation } = res.data.payload;
      setResult({
        status: res.status,
        data: {
          spaces,
          occupation: occupationFormat(occupation),
        },
      });
    } else {
      setResult({
        status: "error",
        message: res.data,
      });
    }
  };

  const getAssetAdSpacesOccupation = async () => {
    const result = await api.getAssetAdSpacesOccupation(filters.asset.id, filters);

    if (result.status === 200) {
      const { adspaces, occupation } = result.data.payload;
      setResult({
        status: result.status,
        data: {
          spaces: adspaces,
          occupation: occupationFormat(occupation),
        },
      });
    } else {
      setResult({
        status: "error",
        message: result.data,
      });
    }
  };

  const fetchAssetOccupation = () => {
    if (!filters.asset?.id) return;

    if (spaceType === SPACE_TYPE.SPACE) {
      getAssetSpaceOccupation(filters);
    } else if (spaceType === SPACE_TYPE.ADSPACE) {
      getAssetAdSpacesOccupation(filters);
    }
  };

  useEffect(() => {
    fetchAssetOccupation();
  }, [filters, spaceType]);

  const handleCreateBlockDates = async (spaceId, start, end, description) => {
    setCreateBlockDatesStatus("loading");

    const formattedStartDate = formatDateUtc(start, "YYYY-MM-DD");
    const formattedEndDate = formatDateUtc(end, "YYYY-MM-DD");

    let res;
    if (spaceType === SPACE_TYPE.SPACE) {
      res = await api.blockDates(spaceId, formattedStartDate, formattedEndDate, description || "");
    } else if (spaceType === SPACE_TYPE.ADSPACE) {
      res = await api.blockAdSpaceDate(spaceId, formattedStartDate, formattedEndDate, description || "");
    }

    if (res.status === 200) {
      setCreateBlockDatesStatus("success");
      fetchAssetOccupation();
    } else {
      setCreateBlockDatesStatus("error");
    }
  };

  const handleUpdateBlockDates = async (spaceId, dateId, start, end, description) => {
    setUpdateBlockDatesStatus("loading");

    const formattedStartDate = formatDateUtc(start, "YYYY-MM-DD");
    const formattedEndDate = formatDateUtc(end, "YYYY-MM-DD");

    let res;
    if (spaceType === SPACE_TYPE.SPACE) {
      res = await api.updateBlockDates(spaceId, dateId, formattedStartDate, formattedEndDate, description || "");
    } else if (spaceType === SPACE_TYPE.ADSPACE) {
      res = await api.updateAdSpaceBlockDates(spaceId, dateId, formattedStartDate, formattedEndDate, description || "");
    }

    if (res.status === 200) {
      setUpdateBlockDatesStatus("success");
      fetchAssetOccupation();
    } else {
      setUpdateBlockDatesStatus("error");
    }
  };

  const handleDeleteBlockDates = async (spaceId, dateId) => {
    setDeleteBlockDatesStatus("loading");

    let res;
    if (spaceType === SPACE_TYPE.SPACE) {
      res = await api.unblockDates(spaceId, dateId);
    } else if (spaceType === SPACE_TYPE.ADSPACE) {
      res = await api.unblockAdSpaceDate(spaceId, dateId);
    }

    if (res.status === 200) {
      setDeleteBlockDatesStatus("success");
      fetchAssetOccupation();
    } else {
      setDeleteBlockDatesStatus("error");
    }
  };

  const handleResetStates = () => {
    setCreateBlockDatesStatus("initial");
    setUpdateBlockDatesStatus("initial");
    setDeleteBlockDatesStatus("initial");
  };

  return (
    <>
      <TimelineVacancy
        filters={filters}
        spaceType={spaceType}
        assets={sortedManagedAssets}
        data={result.data}
        loading={result.status === "loading"}
        filteredDate={dateFilterFormatted(filters)}
        error={result.status === "error"}
        onChangeFilters={handleChangeFilters}
        onChangeSpaceType={setSpaceType}
        onClickBlock={handleClickBlock}
        spaceBlockDates={spaceBlockDates}
        onCreateBlockDates={handleCreateBlockDates}
        onUpdateBlockDates={handleUpdateBlockDates}
        createBlockDatesStatus={createBlockDatesStatus}
        updateBlockDatesStatus={updateBlockDatesStatus}
        onDeleteBlockDates={handleDeleteBlockDates}
        deleteBlockDatesStatus={deleteBlockDatesStatus}
        onResetBlockDatesStatus={handleResetStates}
      />
      <TimelineVacancyModalsContainer />
    </>
  );
};

export default TimelineVacancyContainer;
